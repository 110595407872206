export default [
  {
    path: '/devlet-destekli-alacak-sigortasi',
    name: 'CreditInsurance',
    component: () =>
      import(/* webpackChunkName: "credit-insurance" */'@/views/category-pages/credit-insurance/CreditInsurance')
  },
  {
    path: '/hamilelik-dogum-sigortasi',
    name: 'BirthInsurance',
    component: () =>
      import(/* webpackChunkName: "birth-insurance" */'@/views/category-pages/birth-insurance/BirthInsurance')
  },
  {
    path: '/subemiz-olun',
    name: 'ResellerApply',
    component: () =>
      import(/* webpackChunkName: "reseller-apply" */'@/views/category-pages/reseller-apply/ResellerApply')
  },
  {
    path: '/bes-bireysel-emeklilik-sistemi',
    name: 'IndividualAnnuityInsurance',
    component: () =>
      import(/* webpackChunkName: "bes" */
        '@/views/category-pages/individual-annuity-insurance/IndividualAnnuityInsurance'
      )
  },
  {
    path: '/cep-telefonu-sigortasi',
    name: 'MobilePhoneInsurance',
    component: () =>
      import(/* webpackChunkName: "mobile-insurance" */
        '@/views/category-pages/mobile-phone-insurance/MobilePhoneInsurance'
      )
  },
  {
    path: '/arac-kasko-sigortasi',
    name: 'MotorInsurance',
    component: () =>
      import(/* webpackChunkName: "motor-insurance" */'@/views/category-pages/motor-insurance/MotorInsurance')
  },
  {
    path: '/ferdi-kaza-sigortasi',
    name: 'PersonalAccidentInsurance',
    component: () =>
      import(/* webpackChunkName: "personal-insurance" */
        '@/views/category-pages/personal-accident-insurance/PersonalAccidentInsurance'
      )
  },
  {
    path: '/zorunlu-trafik-sigortasi',
    name: 'CompulsoryTrafficInsurance',
    component: () =>
      import(/* webpackChunkName: "traffic-insurance" */
        '@/views/category-pages/compulsory-traffic-insurance/CompulsoryTrafficInsurance'
      )
  },
  {
    path: '/yabanci-uyruklu-saglik-sigortasi',
    name: 'ForeignHealthInsurance',
    component: () =>
      import(/* webpackChunkName: "foreign-insurance" */
        '@/views/category-pages/foreign-health-insurance/ForeignHealthInsurance'
      )
  },
  {
    path: '/tamamlayici-saglik-sigortasi',
    name: 'ComplementHealthInsurance',
    component: () =>
      import(/* webpackChunkName: "complementary-insurance" */
        '@/views/category-pages/complement-health-insurance/ComplementHealthInsurance'
      )
  },
  {
    path: '/dask-zorunlu-deprem-sigortasi',
    name: 'DaskInsurance',
    component: () =>
      import(/* webpackChunkName: "dask-insurance" */'@/views/category-pages/dask-insurance/DaskInsurance')
  },
  {
    path: '/seyahat-saglik-sigortasi',
    name: 'TravelHealthInsurance',
    component: () =>
      import(/* webpackChunkName: "travel-insurance" */
        '@/views/category-pages/travel-health-insurance/TravelHealthInsurance'
      )
  },
  {
    path: '/ozel-saglik-sigortasi',
    name: 'PrivateHealthInsurance',
    component: () =>
      import(/* webpackChunkName: "health-insurance" */
        '@/views/category-pages/private-health-insurance/PrivateHealthInsurance'
      )
  },
  {
    path: '/ikinci-el-arac-garanti-sigortasi',
    name: 'SecondhandGuaranteeInsurance',
    component: () =>
      import(/* webpackChunkName: "second-hand-insurance" */
        '@/views/category-pages/secondhand-guarantee-insurance/SecondhandGuaranteeInsurance'
      )
  },
  {
    path: '/nakliyat-sigortasi',
    name: 'ShippingInsurance',
    component: () =>
      import(/* webpackChunkName: "transporting-insurance" */'@/views/category-pages/shipping-insurance/ShippingInsurance')
  },
  {
    path: '/kefalet-sigortasi',
    name: 'SuretyInsurance',
    component: () =>
      import(/* webpackChunkName: "surety-insurance" */'@/views/category-pages/surety-insurance/SuretyInsurance')
  },
  {
    path: '/isyeri-sigortasi',
    name: 'CompanyInsurance',
    component: () =>
      import(/* webpackChunkName: "company-insurance" */'@/views/category-pages/company-insurance/CompanyInsurance')
  },
  {
    path: '/konut-sigortasi',
    name: 'HousingInsurance',
    component: () =>
      import(/* webpackChunkName: "home-insurance" */'@/views/category-pages/housing-insurance/HousingInsurance')
  },
  {
    path: '/saglik-sigortasi',
    name: 'HealthInsurance',
    component: () =>
      import(/* webpackChunkName: "health-insurance" */'@/views/category-pages/health-insurance/HealthInsurance')
  }
]
