const GET_VEHICLE_TYPE_URL = "/integration/getVehicleType"
const CITY_LIST_URL = "/cityLookup/list"
const DISTRICT_LIST_URL = "/districtLookup/byCityCode/"
const BRAND_LIST_URL = "/vehicleBrandLookup/list"
const YEAR_LIST_URL = "/vehicleModelYearLookup/byBrandCode/"
const MODEL_LIST_URL = "/vehicleModelLookup/byModelYearCode/"
const JOB_LIST_URL = "/jobLookup/list"
const INFO_REQUEST_URL = "/offer/inforequest/"
const OFFER_POST_URL = "/offer/"
const FETCH_QUESTION_URL = "/questions/?screenId="
const ALL_QUESTIONS_URL = "/questions/list/"
const BLOG_LIST_URL = "/adm/blog/list/"
const HEADERS = {
  "Content-Type": "application/problem+json"
}

export default {
  GET_VEHICLE_TYPE_URL,
  BRAND_LIST_URL,
  YEAR_LIST_URL,
  MODEL_LIST_URL,
  JOB_LIST_URL,
  OFFER_POST_URL,
  CITY_LIST_URL,
  DISTRICT_LIST_URL,
  FETCH_QUESTION_URL,
  HEADERS,
  ALL_QUESTIONS_URL,
  INFO_REQUEST_URL,
  BLOG_LIST_URL
}
