// S.S.S, Biz kimiz?, İletişim, Şube başvurusu gibi sayfalar buraya eklenecektir
export default [
  {
    path: '/hakkimizda',
    name: 'About',
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/about/About')
  },
  {
    path: '/iletisim',
    name: 'Contact',
    component: () =>
      import(/* webpackChunkName: "contact" */ '@/views/contact/Contact')
  },
  {
    path: '/reklam-filmlerimiz',
    name: 'Advertisements',
    component: () =>
      import(/* webpackChunkName: "contact" */ '@/views/advertisements-pages/Advertisements')
  },
  {
    path: '/sikca-sorulan-sorular',
    name: 'FrequentlyAskedQuestions',
    component: () =>
      import(
        /* webpackChunkName: "faq" */ '@/views/asked-questions/AskedQuestions'
      )
  },
  {
    path: '/kisisel-verilerin-korunmasi',
    name: 'Privacy',
    component: () =>
      import(/* webpackChunkName: "privacy" */ '@/views/privacy/Privacy')
  },
  {
    path: '/kullanici-sozlesmesi',
    name: 'Terms',
    component: () =>
      import(/* webpackChunkName: "terms" */ '@/views/terms/Terms')
  },

  {
    path: '/cerez-politikasi',
    name: 'CookiePolicy',
    component: () =>
      import(
        /* webpackChunkName: "cookie-policy" */ '@/views/cookie-policy/CookiePolicy'
      )
  }
]
