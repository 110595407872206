export default [
  {
    path: '/ak-sigorta',
    component: () =>
      import(
        /* webpackChunkName: "aksigorta" */ '@/views/company-pages/ak-sigorta/AkSigortaMain'
      ),
    children: [
      {
        path: '',
        name: 'AkSigorta',
        component: () =>
          import(
            /* webpackChunkName: "ak-sigorta" */ '@/views/company-pages/ak-sigorta/AkSigorta'
          )
      },
      {
        path: 'zorunlu-trafik-sigortasi',
        name: 'AkSigortaCompulsoryTraffic',
        component: () =>
          import(
            /* webpackChunkName: "ak-sigorta" */
            '@/views/company-pages/ak-sigorta/cross-pages/CompulsoryTrafficInsurance'
          )
      },
      {
        path: 'arac-kasko-sigortasi',
        name: 'AkSigortaMotorInsurance',
        component: () =>
          import(
            /* webpackChunkName: "ak-sigorta" */ '@/views/company-pages/ak-sigorta/cross-pages/MotorInsurance'
          )
      },
      {
        path: 'ozel-saglik-sigortasi',
        name: 'AkSigortaHealthInsurance',
        component: () =>
          import(
            /* webpackChunkName: "ak-sigorta" */
            '@/views/company-pages/ak-sigorta/cross-pages/PrivateHealthInsurance'
          )
      },
      {
        path: 'tamamlayici-saglik-sigortasi',
        name: 'AkSigortaComplementaryHealth',
        component: () =>
          import(
            /* webpackChunkName: "ak-sigorta" */
            '@/views/company-pages/ak-sigorta/cross-pages/ComplementaryHealthInsurance'
          )
      },
      {
        path: 'seyahat-saglik-sigortasi',
        name: 'AkSigortaTravelInsurance',
        component: () =>
          import(
            /* webpackChunkName: "ak-sigorta" */
            '@/views/company-pages/ak-sigorta/cross-pages/TravelHealthInsurance'
          )
      },
      {
        path: 'konut-sigortasi',
        name: 'AkSigortaHomeInsurance',
        component: () =>
          import(
            /* webpackChunkName: "ak-sigorta" */ '@/views/company-pages/ak-sigorta/cross-pages/HomeInsurance'
          )
      },
      {
        path: 'ferdi-kaza-sigortasi',
        name: 'AkSigortaPersonalAccident',
        component: () =>
          import(
            /* webpackChunkName: "ak-sigorta" */
            '@/views/company-pages/ak-sigorta/cross-pages/PersonalAccidentInsurance'
          )
      },
      {
        path: 'dask-zorunlu-deprem-sigortasi',
        name: 'AkSigortaDaskInsurance',
        component: () =>
          import(
            /* webpackChunkName: "ak-sigorta" */ '@/views/company-pages/ak-sigorta/cross-pages/DaskInsurance'
          )
      },
      {
        path: 'isyeri-sigortasi',
        name: 'AkSigortaCompanyInsurance',
        component: () =>
          import(
            /* webpackChunkName: "ak-sigorta" */
            '@/views/company-pages/ak-sigorta/cross-pages/CompanyInsurance'
          )
      },
      {
        path: 'nakliyat-sigortasi',
        name: 'AkSigortaTransportation',
        component: () =>
          import(
            /* webpackChunkName: "ak-sigorta" */
            '@/views/company-pages/ak-sigorta/cross-pages/TransportationInsurance'
          )
      }
    ]
  },
  {
    path: '/allianz-sigorta',
    component: () =>
      import(
        /* webpackChunkName: "allianz-sigorta" */ '@/views/company-pages/allianz-sigorta/AllianzSigortaMain'
      ),
    // redirect: { name: 'AllianzSigorta' },
    children: [
      {
        path: '',
        name: 'AllianzSigorta',
        component: () =>
          import(
            /* webpackChunkName: "allianz-sigorta" */ '@/views/company-pages/allianz-sigorta/AllianzSigorta'
          )
      },
      {
        path: 'arac-kasko-sigortasi',
        name: 'AllianzSigortaMotorInsurance',
        component: () =>
          import(
            /* webpackChunkName: "allianz-sigorta" */
            '@/views/company-pages/allianz-sigorta/cross-pages/MotorInsurance'
          )
      },
      {
        path: 'zorunlu-trafik-sigortasi',
        name: 'AllianzSigortaCompulsoryTraffic',
        component: () =>
          import(
            /* webpackChunkName: "allianz-sigorta" */
            '@/views/company-pages/allianz-sigorta/cross-pages/CompulsoryTrafficInsurance'
          )
      },
      {
        path: 'ozel-saglik-sigortasi',
        name: 'AllianzSigortaHealth',
        component: () =>
          import(
            /* webpackChunkName: "allianz-sigorta" */
            '@/views/company-pages/allianz-sigorta/cross-pages/HealthInsurance'
          )
      },
      {
        path: 'konut-sigortasi',
        name: 'AllianzSigortaHomeInsurance',
        component: () =>
          import(
            /* webpackChunkName: "allianz-sigorta" */
            '@/views/company-pages/allianz-sigorta/cross-pages/HomeInsurance'
          )
      },
      {
        path: 'dask-zorunlu-deprem-sigortasi',
        name: 'AllianzSigortaDaskInsurance',
        component: () =>
          import(
            /* webpackChunkName: "allianz-sigorta" */
            '@/views/company-pages/allianz-sigorta/cross-pages/DaskInsurance'
          )
      },
      {
        path: 'isyeri-sigortasi',
        name: 'AllianzSigortaCompanyInsurance',
        component: () =>
          import(
            /* webpackChunkName: "allianz-sigorta" */
            '@/views/company-pages/allianz-sigorta/cross-pages/CompanyInsurance'
          )
      },
      {
        path: 'ferdi-kaza-sigortasi',
        name: 'AllianzSigortaPersonalAccidentInsurance',
        component: () =>
          import(
            /* webpackChunkName: "allianz-sigorta" */
            '@/views/company-pages/allianz-sigorta/cross-pages/PersonalAccidentInsurance'
          )
      },
      {
        path: 'nakliyat-sigortasi',
        name: 'AllianzSigortaTransportationInsurance',
        component: () =>
          import(
            /* webpackChunkName: "allianz-sigorta" */
            '@/views/company-pages/allianz-sigorta/cross-pages/TransportationInsurance'
          )
      },
      {
        path: 'tamamlayici-saglik-sigortasi',
        name: 'AllianzSigortaComplementaryInsurance',
        component: () =>
          import(
            /* webpackChunkName: "allianz-sigorta" */
            '@/views/company-pages/allianz-sigorta/cross-pages/ComplementaryInsurance'
          )
      },
      {
        path: 'seyahat-saglik-sigortasi',
        name: 'AllianzSigortaTravelInsurance',
        component: () =>
          import(
            /* webpackChunkName: "allianz-sigorta" */
            '@/views/company-pages/allianz-sigorta/cross-pages/TravelInsurance'
          )
      }
    ]
  },
  {
    path: '/sompo-sigorta',
    component: () =>
      import(
        /* webpackChunkName: "sompo-sigorta" */ '@/views/company-pages/sompo-sigorta/SompoSigortaMain'
      ),
    children: [
      {
        path: '',
        name: 'SompoSigorta',
        component: () =>
          import(
            /* webpackChunkName: "sompo-sigorta" */ '@/views/company-pages/sompo-sigorta/SompoSigorta'
          )
      },
      {
        path: 'zorunlu-trafik-sigortasi',
        name: 'SompoSigortaCompulsoryTraffic',
        component: () =>
          import(
            /* webpackChunkName: "sompo-sigorta" */
            '@/views/company-pages/sompo-sigorta/cross-pages/CompulsoryTrafficInsurance'
          )
      },
      {
        path: 'arac-kasko-sigortasi',
        name: 'SompoSigortaMotorInsurance',
        component: () =>
          import(
            /* webpackChunkName: "sompo-sigorta" */
            '@/views/company-pages/sompo-sigorta/cross-pages/MotorInsurance'
          )
      },
      {
        path: 'yabanci-uyruklu-saglik-sigortasi',
        name: 'SompoSigortaForeignHealtInsurance',
        component: () =>
          import(
            /* webpackChunkName: "sompo-sigorta" */
            '@/views/company-pages/sompo-sigorta/cross-pages/ForeignHealtInsurance'
          )
      },
      {
        path: 'tamamlayici-saglik-sigortasi',
        name: 'SompoSigortaComplementaryHealth',
        component: () =>
          import(
            /* webpackChunkName: "sompo-sigorta" */
            '@/views/company-pages/sompo-sigorta/cross-pages/ComplementaryHealthInsurance'
          )
      },
      {
        path: 'seyahat-saglik-sigortasi',
        name: 'SompoSigortaTravelHealthInsurance',
        component: () =>
          import(
            /* webpackChunkName: "sompo-sigorta" */
            '@/views/company-pages/sompo-sigorta/cross-pages/TravelHealthInsurance'
          )
      },
      {
        path: 'konut-sigortasi',
        name: 'SompoSigortaHomeInsurance',
        component: () =>
          import(
            /* webpackChunkName: "sompo-sigorta" */
            '@/views/company-pages/sompo-sigorta/cross-pages/HomeInsurance'
          )
      },
      {
        path: 'ferdi-kaza-sigortasi',
        name: 'SompoSigortaPersonalAccident',
        component: () =>
          import(
            /* webpackChunkName: "sompo-sigorta" */
            '@/views/company-pages/sompo-sigorta/cross-pages/PersonalAccidentInsurance'
          )
      },
      {
        path: 'dask-zorunlu-deprem-sigortasi',
        name: 'SompoSigortaDaskInsurance',
        component: () =>
          import(
            /* webpackChunkName: "sompo-sigorta" */
            '@/views/company-pages/sompo-sigorta/cross-pages/DaskInsurance'
          )
      },
      {
        path: 'isyeri-sigortasi',
        name: 'SompoSigortaCompanyInsurance',
        component: () =>
          import(
            /* webpackChunkName: "sompo-sigorta" */
            '@/views/company-pages/sompo-sigorta/cross-pages/CompanyInsurance'
          )
      },
      {
        path: 'nakliyat-sigortasi',
        name: 'SompoSigortaTransportation',
        component: () =>
          import(
            /* webpackChunkName: "sompo-sigorta" */
            '@/views/company-pages/sompo-sigorta/cross-pages/TransportationInsurance'
          )
      },
      {
        path: 'hamilelik-dogum-sigortasi',
        name: 'SompoSigortaBirthInsurance',
        component: () =>
          import(
            /* webpackChunkName: "sompo-sigorta" */
            '@/views/company-pages/sompo-sigorta/cross-pages/BirthInsurance'
          )
      }
    ]
  },
  {
    path: '/turk-nippon-sigorta',
    component: () =>
      import(
        /* webpackChunkName: "turk-nippon" */ '@/views/company-pages/turk-nippon-sigorta/TurkNipponSigortaMain'
      ),
    children: [
      {
        path: '',
        name: 'TurkNipponSigorta',
        component: () =>
          import(
            /* webpackChunkName: "turk-nippon" */ '@/views/company-pages/turk-nippon-sigorta/TurkNipponSigorta'
          )
      },
      {
        path: 'zorunlu-trafik-sigortasi',
        name: 'TurkNipponSigortaCompulsoryTraffic',
        component: () =>
          import(
            /* webpackChunkName: "turk-nippon" */
            '@/views/company-pages/turk-nippon-sigorta/cross-pages/CompulsoryTrafficInsurance'
          )
      },
      {
        path: 'arac-kasko-sigortasi',
        name: 'TurkNipponSigortaMotorInsurance',
        component: () =>
          import(
            /* webpackChunkName: "turk-nippon" */
            '@/views/company-pages/turk-nippon-sigorta/cross-pages/MotorInsurance'
          )
      },
      {
        path: 'dask-zorunlu-deprem-sigortasi',
        name: 'TurkNipponSigortaDaskInsurance',
        component: () =>
          import(
            /* webpackChunkName: "turk-nippon" */
            '@/views/company-pages/turk-nippon-sigorta/cross-pages/DaskInsurance'
          )
      },
      {
        path: 'konut-sigortasi',
        name: 'TurkNipponSigortaHomeInsurance',
        component: () =>
          import(
            /* webpackChunkName: "turk-nippon" */
            '@/views/company-pages/turk-nippon-sigorta/cross-pages/HomeInsurance'
          )
      },
      {
        path: 'isyeri-sigortasi',
        name: 'TurkNipponSigortaCompanyInsurance',
        component: () =>
          import(
            /* webpackChunkName: "turk-nippon" */
            '@/views/company-pages/turk-nippon-sigorta/cross-pages/CompanyInsurance'
          )
      },
      {
        path: 'ferdi-kaza-sigortasi',
        name: 'TurkNipponSigortaPersonalAccident',
        component: () =>
          import(
            /* webpackChunkName: "turk-nippon" */
            '@/views/company-pages/turk-nippon-sigorta/cross-pages/PersonalAccidentInsurance'
          )
      },
      {
        path: 'nakliyat-sigortasi',
        name: 'TurkNipponSigortaTransportation',
        component: () =>
          import(
            /* webpackChunkName: "turk-nippon" */
            '@/views/company-pages/turk-nippon-sigorta/cross-pages/TransportationInsurance'
          )
      },
      {
        path: 'ikinci-el-arac-garanti-sigortasi',
        name: 'TurkNipponSigortaSecondhandGuaranteeInsurance',
        component: () =>
          import(
            /* webpackChunkName: "turk-nippon" */
            '@/views/company-pages/turk-nippon-sigorta/cross-pages/SecondhandGuaranteeInsurance'
          )
      },
      {
        path: 'tamamlayici-saglik-sigortasi',
        name: 'TurkNipponSigortaComplementaryHealth',
        component: () =>
          import(
            /* webpackChunkName: "turk-nippon" */
            '@/views/company-pages/turk-nippon-sigorta/cross-pages/ComplementaryHealthInsurance'
          )
      },
      {
        path: 'seyahat-saglik-sigortasi',
        name: 'TurkNipponSigortaTravelInsurance',
        component: () =>
          import(
            /* webpackChunkName: "turk-nippon" */
            '@/views/company-pages/turk-nippon-sigorta/cross-pages/TravelHealthInsurance'
          )
      },
      {
        path: 'hamilelik-dogum-sigortasi',
        name: 'TurkNipponSigortaBirthInsurance',
        component: () =>
          import(
            /* webpackChunkName: "turk-nippon" */
            '@/views/company-pages/turk-nippon-sigorta/cross-pages/BirthInsurance'
          )
      }
    ]
  },
  {
    path: '/turkiye-sigorta',
    component: () =>
      import(
        /* webpackChunkName: "turkiye-sigorta" */ '@/views/company-pages/turkiye-sigorta/TurkiyeSigortaMain'
      ),
    children: [
      {
        path: '',
        name: 'TurkiyeSigorta',
        component: () =>
          import(
            /* webpackChunkName: "turkiye-sigorta" */ '@/views/company-pages/turkiye-sigorta/TurkiyeSigorta'
          )
      },
      {
        path: 'zorunlu-trafik-sigortasi',
        name: 'TurkiyeSigortaCompulsoryTraffic',
        component: () =>
          import(
            /* webpackChunkName: "turkiye-sigorta" */
            '@/views/company-pages/turkiye-sigorta/cross-pages/CompulsoryTrafficInsurance'
          )
      },
      {
        path: 'arac-kasko-sigortasi',
        name: 'TurkiyeSigortaMotorInsurance',
        component: () =>
          import(
            /* webpackChunkName: "turkiye-sigorta" */
            '@/views/company-pages/turkiye-sigorta/cross-pages/MotorInsurance'
          )
      },
      {
        path: 'ozel-saglik-sigortasi',
        name: 'TurkiyeSigortaHealthInsurance',
        component: () =>
          import(
            /* webpackChunkName: "turkiye-sigorta" */
            '@/views/company-pages/turkiye-sigorta/cross-pages/PrivateHealthInsurance'
          )
      },
      {
        path: 'tamamlayici-saglik-sigortasi',
        name: 'TurkiyeSigortaComplementaryHealth',
        component: () =>
          import(
            /* webpackChunkName: "turkiye-sigorta" */
            '@/views/company-pages/turkiye-sigorta/cross-pages/ComplementaryHealthInsurance'
          )
      },
      {
        path: 'seyahat-saglik-sigortasi',
        name: 'TurkiyeSigortaTravelInsurance',
        component: () =>
          import(
            /* webpackChunkName: "turkiye-sigorta" */
            '@/views/company-pages/turkiye-sigorta/cross-pages/TravelHealthInsurance'
          )
      },
      {
        path: 'konut-sigortasi',
        name: 'TurkiyeSigortaHomeInsurance',
        component: () =>
          import(
            /* webpackChunkName: "turkiye-sigorta" */
            '@/views/company-pages/turkiye-sigorta/cross-pages/HomeInsurance'
          )
      },
      {
        path: 'ferdi-kaza-sigortasi',
        name: 'TurkiyeSigortaPersonalAccident',
        component: () =>
          import(
            /* webpackChunkName: "turkiye-sigorta" */
            '@/views/company-pages/turkiye-sigorta/cross-pages/PersonalAccidentInsurance'
          )
      },
      {
        path: 'dask-zorunlu-deprem-sigortasi',
        name: 'TurkiyeSigortaDaskInsurance',
        component: () =>
          import(
            /* webpackChunkName: "turkiye-sigorta" */
            '@/views/company-pages/turkiye-sigorta/cross-pages/DaskInsurance'
          )
      },
      {
        path: 'isyeri-sigortasi',
        name: 'TurkiyeSigortaCompanyInsurance',
        component: () =>
          import(
            /* webpackChunkName: "turkiye-sigorta" */
            '@/views/company-pages/turkiye-sigorta/cross-pages/CompanyInsurance'
          )
      },
      {
        path: 'nakliyat-sigortasi',
        name: 'TurkiyeSigortaTransportation',
        component: () =>
          import(
            /* webpackChunkName: "turkiye-sigorta" */
            '@/views/company-pages/turkiye-sigorta/cross-pages/TransportationInsurance'
          )
      }
    ]
  },

  {
    path: '/ankara-sigorta',
    component: () =>
      import(
        /* webpackChunkName: "ankara-sigorta" */ '@/views/company-pages/ankara-sigorta/AnkaraSigortaMain'
      ),
    children: [
      {
        path: '',
        name: 'AnkaraSigorta',
        component: () =>
          import(
            /* webpackChunkName: "ankara-sigorta" */ '@/views/company-pages/ankara-sigorta/AnkaraSigorta'
          )
      },
      {
        path: 'zorunlu-trafik-sigortasi',
        name: 'AnkaraSigortaCompulsoryTraffic',
        component: () =>
          import(
            /* webpackChunkName: "ankara-sigorta" */
            '@/views/company-pages/ankara-sigorta/cross-pages/CompulsoryTrafficInsurance'
          )
      },
      {
        path: 'arac-kasko-sigortasi',
        name: 'AnkaraSigortaMotorInsurance',
        component: () =>
          import(
            /* webpackChunkName: "ankara-sigorta" */
            '@/views/company-pages/ankara-sigorta/cross-pages/MotorInsurance'
          )
      },
      {
        path: 'yabanci-uyruklu-saglik-sigortasi',
        name: 'AnkaraSigortaForeignHealtInsurance',
        component: () =>
          import(
            /* webpackChunkName: "ankara-sigorta" */
            '@/views/company-pages/ankara-sigorta/cross-pages/ForeignHealtInsurance'
          )
      },
      {
        path: 'tamamlayici-saglik-sigortasi',
        name: 'AnkaraSigortaComplementaryHealth',
        component: () =>
          import(
            /* webpackChunkName: "ankara-sigorta" */
            '@/views/company-pages/ankara-sigorta/cross-pages/ComplementaryHealthInsurance'
          )
      },
      {
        path: 'seyahat-saglik-sigortasi',
        name: 'AnkaraSigortaTravelHealtInsurance',
        component: () =>
          import(
            /* webpackChunkName: "ankara-sigorta" */
            '@/views/company-pages/ankara-sigorta/cross-pages/TravelHealthInsurance'
          )
      },
      {
        path: 'konut-sigortasi',
        name: 'AnkaraSigortaHomeInsurance',
        component: () =>
          import(
            /* webpackChunkName: "ankara-sigorta" */
            '@/views/company-pages/ankara-sigorta/cross-pages/HomeInsurance'
          )
      },
      {
        path: 'ferdi-kaza-sigortasi',
        name: 'AnkaraSigortaPersonalAccident',
        component: () =>
          import(
            /* webpackChunkName: "ankara-sigorta" */
            '@/views/company-pages/ankara-sigorta/cross-pages/PersonalAccidentInsurance'
          )
      },
      {
        path: 'dask-zorunlu-deprem-sigortasi',
        name: 'AnkaraSigortaDaskInsurance',
        component: () =>
          import(
            /* webpackChunkName: "ankara-sigorta" */
            '@/views/company-pages/ankara-sigorta/cross-pages/DaskInsurance'
          )
      },
      {
        path: 'isyeri-sigortasi',
        name: 'AnkaraSigortaCompanyInsurance',
        component: () =>
          import(
            /* webpackChunkName: "ankara-sigorta" */
            '@/views/company-pages/ak-sigorta/cross-pages/CompanyInsurance'
          )
      },
      {
        path: 'nakliyat-sigortasi',
        name: 'AnkaraSigortaTransportation',
        component: () =>
          import(
            /* webpackChunkName: "ankara-sigorta" */
            '@/views/company-pages/ankara-sigorta/cross-pages/TransportationInsurance'
          )
      }
    ]
  },
  {
    path: '/atlas-mutuel-sigorta',
    component: () =>
      import(
        /* webpackChunkName: "atlas-mutuel" */ '@/views/company-pages/atlas-mutuel/AtlasMutuelMain'
      ),
    children: [
      {
        path: '',
        name: 'AtlasMutuel',
        component: () =>
          import(
            /* webpackChunkName: "atlas-mutuel" */ '@/views/company-pages/atlas-mutuel/AtlasMutuel'
          )
      },
      {
        path: 'arac-kasko-sigortasi',
        name: 'AtlasMutuelMotorInsurance',
        component: () =>
          import(
            /* webpackChunkName: "atlas-mutuel" */
            '@/views/company-pages/atlas-mutuel/cross-pages/MotorInsurance'
          )
      },
      {
        path: 'konut-sigortasi',
        name: 'AtlasMutuelHomeInsurance',
        component: () =>
          import(
            /* webpackChunkName: "atlas-mutuel" */ '@/views/company-pages/atlas-mutuel/cross-pages/HomeInsurance'
          )
      },
      {
        path: 'dask-zorunlu-deprem-sigortasi',
        name: 'AtlasMutuelDask',
        component: () =>
          import(
            /* webpackChunkName: "atlas-mutuel" */ '@/views/company-pages/atlas-mutuel/cross-pages/DaskInsurance'
          )
      },
      {
        path: 'isyeri-sigortasi',
        name: 'AtlasMutuelCompanyInsurance',
        component: () =>
          import(
            /* webpackChunkName: "atlas-mutuel" */
            '@/views/company-pages/atlas-mutuel/cross-pages/CompanyInsurance'
          )
      },
      {
        path: 'ferdi-kaza-sigortasi',
        name: 'AtlasMutuelPersonalAccident',
        component: () =>
          import(
            /* webpackChunkName: "atlas-mutuel" */
            '@/views/company-pages/atlas-mutuel/cross-pages/PersonalAccidentInsurance'
          )
      },
      {
        path: 'nakliyat-sigortasi',
        name: 'AtlasMutuelTransportation',
        component: () =>
          import(
            /* webpackChunkName: "atlas-mutuel" */
            '@/views/company-pages/atlas-mutuel/cross-pages/TransportationInsurance'
          )
      },
      {
        path: 'seyahat-saglik-sigortasi',
        name: 'AtlasMutuelTravelInsurance',
        component: () =>
          import(
            /* webpackChunkName: "atlas-mutuel" */
            '@/views/company-pages/atlas-mutuel/cross-pages/TravelHealthInsurance'
          )
      }
    ]
  },
  {
    path: '/mapfre-sigorta',
    component: () =>
      import(
        /* webpackChunkName: "mapfre-sigorta" */ '@/views/company-pages/mapfre-sigorta/MapfreSigortaMain'
      ),
    children: [
      {
        path: '',
        name: 'MapfreSigorta',
        component: () =>
          import(
            /* webpackChunkName: "mapfre-sigorta" */ '@/views/company-pages/mapfre-sigorta/MapfreSigorta'
          )
      },
      {
        path: 'zorunlu-trafik-sigortasi',
        name: 'MapfreSigortaCompulsoryTraffic',
        component: () =>
          import(
            /* webpackChunkName: "mapfre-sigorta" */
            '@/views/company-pages/mapfre-sigorta/cross-pages/CompulsoryTrafficInsurance'
          )
      },
      {
        path: 'arac-kasko-sigortasi',
        name: 'MapfreSigortaMotorInsurance',
        component: () =>
          import(
            /* webpackChunkName: "mapfre-sigorta" */
            '@/views/company-pages/mapfre-sigorta/cross-pages/MotorInsurance'
          )
      },
      {
        path: 'ozel-saglik-sigortasi',
        name: 'MapfreSigortHealthInsurance',
        component: () =>
          import(
            /* webpackChunkName: "mapfre-sigorta" */
            '@/views/company-pages/mapfre-sigorta/cross-pages/PrivateHealthInsurance'
          )
      },
      {
        path: 'tamamlayici-saglik-sigortasi',
        name: 'MapfreSigortaComplementaryHealth',
        component: () =>
          import(
            /* webpackChunkName: "mapfre-sigorta" */
            '@/views/company-pages/mapfre-sigorta/cross-pages/ComplementaryHealthInsurance'
          )
      },
      {
        path: 'seyahat-saglik-sigortasi',
        name: 'MapfreSigortaTravelInsurance',
        component: () =>
          import(
            /* webpackChunkName: "mapfre-sigorta" */
            '@/views/company-pages/mapfre-sigorta/cross-pages/TravelHealthInsurance'
          )
      },
      {
        path: 'konut-sigortasi',
        name: 'MapfreSigortaHomeInsurance',
        component: () =>
          import(
            /* webpackChunkName: "mapfre-sigorta" */
            '@/views/company-pages/mapfre-sigorta/cross-pages/HomeInsurance'
          )
      },
      {
        path: 'ferdi-kaza-sigortasi',
        name: 'MapfreSigortaPersonalAccident',
        component: () =>
          import(
            /* webpackChunkName: "mapfre-sigorta" */
            '@/views/company-pages/mapfre-sigorta/cross-pages/PersonalAccidentInsurance'
          )
      },
      {
        path: 'dask-zorunlu-deprem-sigortasi',
        name: 'MapfreSigortaDaskInsurance',
        component: () =>
          import(
            /* webpackChunkName: "mapfre-sigorta" */
            '@/views/company-pages/mapfre-sigorta/cross-pages/DaskInsurance'
          )
      },
      {
        path: 'isyeri-sigortasi',
        name: 'MapfreSigortaCompanyInsurance',
        component: () =>
          import(
            /* webpackChunkName: "mapfre-sigorta" */
            '@/views/company-pages/mapfre-sigorta/cross-pages/CompanyInsurance'
          )
      },
      {
        path: 'nakliyat-sigortasi',
        name: 'MapfreSigortaTransportation',
        component: () =>
          import(
            /* webpackChunkName: "mapfre-sigorta" */
            '@/views/company-pages/mapfre-sigorta/cross-pages/TransportationInsurance'
          )
      }
    ]
  },
  {
    path: '/fiba-emeklilik',
    component: () =>
      import(
        /* webpackChunkName: "fiba" */ '@/views/company-pages/fiba-emeklilik/FibaEmeklilikMain'
      ),
    children: [
      {
        path: '',
        name: 'FibaEmeklilik',
        component: () =>
          import(
            /* webpackChunkName: "fiba" */ '@/views/company-pages/fiba-emeklilik/FibaEmeklilik'
          )
      },
      {
        path: 'bes-bireysel-emeklilik-sistemi',
        name: 'FibaEmeklilikRetirement',
        component: () =>
          import(
            /* webpackChunkName: "fiba" */ '@/views/company-pages/fiba-emeklilik/cross-pages/Retirement'
          )
      }
    ]
  },
  {
    path: '/quick-sigorta',
    component: () =>
      import(
        /* webpackChunkName: "quick-sigorta" */ '@/views/company-pages/quick-sigorta/QuickSigortaMain'
      ),
    children: [
      {
        path: '',
        name: 'QuickSigorta',
        component: () =>
          import(
            /* webpackChunkName: "quick-sigorta" */ '@/views/company-pages/quick-sigorta/QuickSigorta'
          )
      },
      {
        path: 'zorunlu-trafik-sigortasi',
        name: 'QuickSigortaCompulsoryTraffic',
        component: () =>
          import(
            /* webpackChunkName: "quick-sigorta" */
            '@/views/company-pages/quick-sigorta/cross-pages/CompulsoryTrafficInsurance'
          )
      },
      {
        path: 'arac-kasko-sigortasi',
        name: 'QuickSigortaMotorInsurance',
        component: () =>
          import(
            /* webpackChunkName: "quick-sigorta" */
            '@/views/company-pages/quick-sigorta/cross-pages/MotorInsurance'
          )
      },
      {
        path: 'dask-zorunlu-deprem-sigortasi',
        name: 'QuickSigortaDaskInsurance',
        component: () =>
          import(
            /* webpackChunkName: "quick-sigorta" */
            '@/views/company-pages/quick-sigorta/cross-pages/DaskInsurance'
          )
      },
      {
        path: 'konut-sigortasi',
        name: 'QuickSigortaHomeInsurance',
        component: () =>
          import(
            /* webpackChunkName: "quick-sigorta" */
            '@/views/company-pages/quick-sigorta/cross-pages/HomeInsurance'
          )
      },
      {
        path: 'isyeri-sigortasi',
        name: 'QuickSigortaCompanyInsurance',
        component: () =>
          import(
            /* webpackChunkName: "quick-sigorta" */
            '@/views/company-pages/quick-sigorta/cross-pages/CompanyInsurance'
          )
      },
      {
        path: 'ferdi-kaza-sigortasi',
        name: 'QuickSigortaPersonalInsurance',
        component: () =>
          import(
            /* webpackChunkName: "quick-sigorta" */
            '@/views/company-pages/quick-sigorta/cross-pages/PersonalAccidentInsurance'
          )
      },
      {
        path: 'nakliyat-sigortasi',
        name: 'QuickSigortaTransportationInsurance',
        component: () =>
          import(
            /* webpackChunkName: "quick-sigorta" */
            '@/views/company-pages/quick-sigorta/cross-pages/TransportationInsurance'
          )
      },
      {
        path: 'kefalet-sigortasi',
        name: 'QuickSigortaSuretyInsurance',
        component: () =>
          import(
            /* webpackChunkName: "quick-sigorta" */
            '@/views/company-pages/quick-sigorta/cross-pages/SuretyInsurance'
          )
      },
      {
        path: 'devlet-destekli-alacak-sigortasi',
        name: 'QuickSigortaCreditInsurance',
        component: () =>
          import(
            /* webpackChunkName: "quick-sigorta" */
            '@/views/company-pages/quick-sigorta/cross-pages/CreditInsurance'
          )
      },
      {
        path: 'seyahat-saglik-sigortasi',
        name: 'QuickSigortaTravelInsurance',
        component: () =>
          import(
            /* webpackChunkName: "quick-sigorta" */
            '@/views/company-pages/quick-sigorta/cross-pages/TravelInsurance'
          )
      }
    ]
  },
  {
    path: '/ray-sigorta',
    component: () =>
      import(
        /* webpackChunkName: "ray-sigorta" */ '@/views/company-pages/ray-sigorta/RaySigortaMain'
      ),
    children: [
      {
        path: '',
        name: 'RaySigorta',
        component: () =>
          import(
            /* webpackChunkName: "ray-sigorta" */ '@/views/company-pages/ray-sigorta/RaySigorta'
          )
      },
      {
        path: 'zorunlu-trafik-sigortasi',
        name: 'RaySigortaCompulsoryTraffic',
        component: () =>
          import(
            /* webpackChunkName: "ray-sigorta" */
            '@/views/company-pages/ray-sigorta/cross-pages/CompulsoryTrafficInsurance'
          )
      },
      {
        path: 'arac-kasko-sigortasi',
        name: 'RaySigortaMotorInsurance',
        component: () =>
          import(
            /* webpackChunkName: "ray-sigorta" */
            '@/views/company-pages/ray-sigorta/cross-pages/MotorInsurance'
          )
      },
      {
        path: 'dask-zorunlu-deprem-sigortasi',
        name: 'RaySigortaDaskInsurance',
        component: () =>
          import(
            /* webpackChunkName: "ray-sigorta" */
            '@/views/company-pages/ray-sigorta/cross-pages/DaskInsurance'
          )
      },
      {
        path: 'konut-sigortasi',
        name: 'RaySigortaHomeInsurance',
        component: () =>
          import(
            /* webpackChunkName: "ray-sigorta" */
            '@/views/company-pages/ray-sigorta/cross-pages/HomeInsurance'
          )
      },
      {
        path: 'isyeri-sigortasi',
        name: 'RaySigortaCompanyInsurance',
        component: () =>
          import(
            /* webpackChunkName: "ray-sigorta" */
            '@/views/company-pages/ray-sigorta/cross-pages/CompanyInsurance'
          )
      },
      {
        path: 'ferdi-kaza-sigortasi',
        name: 'RaySigortaPersonalAccident',
        component: () =>
          import(
            /* webpackChunkName: "ray-sigorta" */
            '@/views/company-pages/ray-sigorta/cross-pages/PersonalAccidentInsurance'
          )
      },
      {
        path: 'nakliyat-sigortasi',
        name: 'RaySigortaTransportation',
        component: () =>
          import(
            /* webpackChunkName: "ray-sigorta" */
            '@/views/company-pages/ray-sigorta/cross-pages/TransportationInsurance'
          )
      },
      {
        path: 'tamamlayici-saglik-sigortasi',
        name: 'RaySigortaComplementaryHealth',
        component: () =>
          import(
            /* webpackChunkName: "ray-sigorta" */
            '@/views/company-pages/ray-sigorta/cross-pages/ComplementaryHealthInsurance'
          )
      },
      {
        path: 'seyahat-saglik-sigortasi',
        name: 'RaySigortaTravelInsurance',
        component: () =>
          import(
            /* webpackChunkName: "ray-sigorta" */
            '@/views/company-pages/ray-sigorta/cross-pages/TravelHealthInsurance'
          )
      }
    ]
  },
  {
    path: '/hdi-sigorta',
    component: () =>
      import(
        /* webpackChunkName: "hdi-sigorta" */ '@/views/company-pages/hdi-sigorta/HdiSigortaMain'
      ),
    children: [
      {
        path: '',
        name: 'HdiSigorta',
        component: () =>
          import(
            /* webpackChunkName: "hdi-sigorta" */ '@/views/company-pages/hdi-sigorta/HdiSigorta'
          )
      },
      {
        path: 'zorunlu-trafik-sigortasi',
        name: 'HdiSigortaCompulsoryTraffic',
        component: () =>
          import(
            /* webpackChunkName: "hdi-sigorta" */
            '@/views/company-pages/hdi-sigorta/cross-pages/CompulsoryTrafficInsurance'
          )
      },
      {
        path: 'arac-kasko-sigortasi',
        name: 'HdiSigortaMotorInsurance',
        component: () =>
          import(
            /* webpackChunkName: "hdi-sigorta" */ '@/views/company-pages/hdi-sigorta/cross-pages/MotorInsurance'
          )
      },
      {
        path: 'dask-zorunlu-deprem-sigortasi',
        name: 'HdiSigortaDaskInsurance',
        component: () =>
          import(
            /* webpackChunkName: "hdi-sigorta" */ '@/views/company-pages/hdi-sigorta/cross-pages/DaskInsurance'
          )
      },
      {
        path: 'konut-sigortasi',
        name: 'HdiSigortaHomeInsurance',
        component: () =>
          import(
            /* webpackChunkName: "hdi-sigorta" */ '@/views/company-pages/hdi-sigorta/cross-pages/HomeInsurance'
          )
      },
      {
        path: 'isyeri-sigortasi',
        name: 'HdiSigortaCompanyInsurance',
        component: () =>
          import(
            /* webpackChunkName: "hdi-sigorta" */
            '@/views/company-pages/hdi-sigorta/cross-pages/CompanyInsurance'
          )
      },
      {
        path: 'ferdi-kaza-sigortasi',
        name: 'HdiSigortaPersonalAccident',
        component: () =>
          import(
            /* webpackChunkName: "hdi-sigorta" */
            '@/views/company-pages/hdi-sigorta/cross-pages/PersonalAccidentInsurance'
          )
      },
      {
        path: 'nakliyat-sigortasi',
        name: 'HdiSigortaTransportation',
        component: () =>
          import(
            /* webpackChunkName: "hdi-sigorta" */
            '@/views/company-pages/hdi-sigorta/cross-pages/TransportationInsurance'
          )
      },
      {
        path: 'cep-telefonu-sigortasi',
        name: 'HdiSigortaMobileInsurance',
        component: () =>
          import(
            /* webpackChunkName: "hdi-sigorta" */
            '@/views/company-pages/hdi-sigorta/cross-pages/MobileInsurance'
          )
      },
      {
        path: 'tamamlayici-saglik-sigortasi',
        name: 'HdiSigortaComplementaryHealth',
        component: () =>
          import(
            /* webpackChunkName: "hdi-sigorta" */
            '@/views/company-pages/hdi-sigorta/cross-pages/ComplementaryHealthInsurance'
          )
      },
      {
        path: 'seyahat-saglik-sigortasi',
        name: 'HdiSigortaTravelInsurance',
        component: () =>
          import(
            /* webpackChunkName: "hdi-sigorta" */
            '@/views/company-pages/hdi-sigorta/cross-pages/TravelHealthInsurance'
          )
      }
    ]
  },
  {
    path: '/generali-sigorta',
    component: () =>
      import(
        /* webpackChunkName: "generali-sigorta" */ '@/views/company-pages/generali-sigorta/GeneraliSigortaMain'
      ),
    children: [
      {
        path: '',
        name: 'GeneraliSigorta',
        component: () =>
          import(
            /* webpackChunkName: "generali-sigorta" */ '@/views/company-pages/generali-sigorta/GeneraliSigorta'
          )
      },
      {
        path: 'zorunlu-trafik-sigortasi',
        name: 'GeneraliSigortaCompulsoryTraffic',
        component: () =>
          import(
            /* webpackChunkName: "generali-sigorta" */
            '@/views/company-pages/generali-sigorta/cross-pages/CompulsoryTrafficInsurance'
          )
      },
      {
        path: 'arac-kasko-sigortasi',
        name: 'GeneraliSigortaMotorInsurance',
        component: () =>
          import(
            /* webpackChunkName: "generali-sigorta" */
            '@/views/company-pages/generali-sigorta/cross-pages/MotorInsurance'
          )
      },
      {
        path: 'dask-zorunlu-deprem-sigortasi',
        name: 'GeneraliSigortaDaskInsurance',
        component: () =>
          import(
            /* webpackChunkName: "generali-sigorta" */
            '@/views/company-pages/generali-sigorta/cross-pages/DaskInsurance'
          )
      },
      {
        path: 'konut-sigortasi',
        name: 'GeneraliSigortaHomeInsurance',
        component: () =>
          import(
            /* webpackChunkName: "generali-sigorta" */
            '@/views/company-pages/generali-sigorta/cross-pages/HomeInsurance'
          )
      },
      {
        path: 'isyeri-sigortasi',
        name: 'GeneraliSigortaCompanyInsurance',
        component: () =>
          import(
            /* webpackChunkName: "generali-sigorta" */
            '@/views/company-pages/generali-sigorta/cross-pages/CompanyInsurance'
          )
      },
      {
        path: 'ferdi-kaza-sigortasi',
        name: 'GeneraliSigortaPersonalAccident',
        component: () =>
          import(
            /* webpackChunkName: "generali-sigorta" */
            '@/views/company-pages/generali-sigorta/cross-pages/PersonalAccidentInsurance'
          )
      },
      {
        path: 'nakliyat-sigortasi',
        name: 'GeneraliSigortaTransportation',
        component: () =>
          import(
            /* webpackChunkName: "generali-sigorta" */
            '@/views/company-pages/generali-sigorta/cross-pages/TransportationInsurance'
          )
      },
      {
        path: 'seyahat-saglik-sigortasi',
        name: 'GeneraliSigortaTravelInsurance',
        component: () =>
          import(
            /* webpackChunkName: "generali-sigorta" */
            '@/views/company-pages/generali-sigorta/cross-pages/TravelHealthInsurance'
          )
      }
    ]
  },
  {
    path: '/groupama-sigorta',
    component: () =>
      import(
        /* webpackChunkName: "groupama-sigorta" */ '@/views/company-pages/groupama-sigorta/GroupamaSigortaMain'
      ),
    children: [
      {
        path: '',
        name: 'GroupamaSigorta',
        component: () =>
          import(
            /* webpackChunkName: "groupama-sigorta" */ '@/views/company-pages/groupama-sigorta/GroupamaSigorta'
          )
      },
      {
        path: 'arac-kasko-sigortasi',
        name: 'GroupamaSigortaMotorInsurance',
        component: () =>
          import(
            /* webpackChunkName: "groupama-sigorta" */
            '@/views/company-pages/groupama-sigorta/cross-pages/MotorInsurance'
          )
      },
      {
        path: 'zorunlu-trafik-sigortasi',
        name: 'GroupamaSigortaCompulsoryTraffic',
        component: () =>
          import(
            /* webpackChunkName: "groupama-sigorta" */
            '@/views/company-pages/groupama-sigorta/cross-pages/CompulsoryTrafficInsurance'
          )
      },
      {
        path: 'yabanci-uyruklu-saglik-sigortasi',
        name: 'GroupamaSigortaForeignInsurance',
        component: () =>
          import(
            /* webpackChunkName: "groupama-sigorta" */
            '@/views/company-pages/groupama-sigorta/cross-pages/ForeignInsurance'
          )
      },
      {
        path: 'dask-zorunlu-deprem-sigortasi',
        name: 'GroupamaSigortaDaskInsurance',
        component: () =>
          import(
            /* webpackChunkName: "groupama-sigorta" */
            '@/views/company-pages/groupama-sigorta/cross-pages/DaskInsurance'
          )
      },
      {
        path: 'konut-sigortasi',
        name: 'GroupamaSigortaHomeInsurance',
        component: () =>
          import(
            /* webpackChunkName: "groupama-sigorta" */
            '@/views/company-pages/groupama-sigorta/cross-pages/HomeInsurance'
          )
      },
      {
        path: 'isyeri-sigortasi',
        name: 'GroupamaSigortaCompanyInsurance',
        component: () =>
          import(
            /* webpackChunkName: "groupama-sigorta" */
            '@/views/company-pages/groupama-sigorta/cross-pages/CompanyInsurance'
          )
      },
      {
        path: 'ferdi-kaza-sigortasi',
        name: 'GroupamaSigortaPersonalAccident',
        component: () =>
          import(
            /* webpackChunkName: "groupama-sigorta" */
            '@/views/company-pages/groupama-sigorta/cross-pages/PersonalAccidentInsurance'
          )
      },
      {
        path: 'nakliyat-sigortasi',
        name: 'GroupamaSigortaTransportation',
        component: () =>
          import(
            /* webpackChunkName: "groupama-sigorta" */
            '@/views/company-pages/groupama-sigorta/cross-pages/TransportationInsurance'
          )
      },
      {
        path: 'ozel-saglik-sigortasi',
        name: 'GroupamaSigortHealthInsurance',
        component: () =>
          import(
            /* webpackChunkName: "groupama-sigorta" */
            '@/views/company-pages/groupama-sigorta/cross-pages/PrivateHealthInsurance'
          )
      },
      {
        path: 'tamamlayici-saglik-sigortasi',
        name: 'GroupamaSigortaComplementaryHealth',
        component: () =>
          import(
            /* webpackChunkName: "groupama-sigorta" */
            '@/views/company-pages/groupama-sigorta/cross-pages/ComplementaryHealthInsurance'
          )
      },
      {
        path: 'yabanci-uyruklu-saglik-sigortasi',
        name: 'GroupamaSigortaForeignHealtInsurance',
        component: () =>
          import(
            '@/views/company-pages/groupama-sigorta/cross-pages/ForeignHealtInsurance'
          )
      },
      {
        path: 'seyahat-saglik-sigortasi',
        name: 'GroupamaSigortaTravelInsurance',
        component: () =>
          import(
            /* webpackChunkName: "groupama-sigorta" */
            '@/views/company-pages/groupama-sigorta/cross-pages/TravelHealthInsurance'
          )
      }
    ]
  },
  {
    path: '/demir-sigorta',
    component: () =>
      import(
        /* webpackChunkName: "demir-sigorta" */ '@/views/company-pages/demir-sigorta/DemirSigortaMain'
      ),
    children: [
      {
        path: '',
        name: 'DemirSigorta',
        component: () =>
          import(
            /* webpackChunkName: "demir-sigorta" */ '@/views/company-pages/demir-sigorta/DemirSigorta'
          )
      },
      {
        path: 'ozel-saglik-sigortasi',
        name: 'DemirSigortaHealthInsurance',
        component: () =>
          import(
            /* webpackChunkName: "demir-sigorta" */
            '@/views/company-pages/demir-sigorta/cross-pages/PrivateHealthInsurance'
          )
      },
      {
        path: 'tamamlayici-saglik-sigortasi',
        name: 'DemirSigortaComplementaryInsurance',
        component: () =>
          import(
            /* webpackChunkName: "demir-sigorta" */
            '@/views/company-pages/demir-sigorta/cross-pages/ComplementaryInsurance'
          )
      },
      {
        path: 'hamilelik-dogum-sigortasi',
        name: 'DemirSigortaBirthInsurance',
        component: () =>
          import(
            /* webpackChunkName: "demir-sigorta" */
            '@/views/company-pages/demir-sigorta/cross-pages/BirthInsurance'
          )
      },
      {
        path: 'yabanci-uyruklu-saglik-sigortasi',
        name: 'DemirSigortaForeignInsurance',
        component: () =>
          import(
            /* webpackChunkName: "demir-sigorta" */
            '@/views/company-pages/demir-sigorta/cross-pages/ForeignInsurance'
          )
      }
    ]
  },
  {
    path: '/bereket-sigorta',
    component: () =>
      import(
        /* webpackChunkName: "bereket-sigorta" */ '@/views/company-pages/bereket-sigorta/BereketSigortaMain'
      ),
    children: [
      {
        path: '',
        name: 'BereketSigorta',
        component: () =>
          import(
            /* webpackChunkName: "bereket-sigorta" */ '@/views/company-pages/bereket-sigorta/BereketSigorta'
          )
      },
      {
        path: 'arac-kasko-sigortasi',
        name: 'BereketSigortaMotorInsurance',
        component: () =>
          import(
            /* webpackChunkName: "bereket-sigorta" */
            '@/views/company-pages/bereket-sigorta/cross-pages/MotorInsurance'
          )
      },
      {
        path: 'zorunlu-trafik-sigortasi',
        name: 'BereketSigortaCompulsoryTraffic',
        component: () =>
          import(
            /* webpackChunkName: "bereket-sigorta" */
            '@/views/company-pages/bereket-sigorta/cross-pages/CompulsoryTrafficInsurance'
          )
      },
      {
        path: 'konut-sigortasi',
        name: 'BereketSigortaHomeInsurance',
        component: () =>
          import(
            /* webpackChunkName: "bereket-sigorta" */
            '@/views/company-pages/bereket-sigorta/cross-pages/HomeInsurance'
          )
      },
      {
        path: 'dask-zorunlu-deprem-sigortasi',
        name: 'BereketSigortaDaskInsurance',
        component: () =>
          import(
            /* webpackChunkName: "bereket-sigorta" */
            '@/views/company-pages/bereket-sigorta/cross-pages/DaskInsurance'
          )
      },
      {
        path: 'isyeri-sigortasi',
        name: 'BereketSigortaCompanyInsurance',
        component: () =>
          import(
            /* webpackChunkName: "bereket-sigorta" */
            '@/views/company-pages/bereket-sigorta/cross-pages/CompanyInsurance'
          )
      },
      {
        path: 'ferdi-kaza-sigortasi',
        name: 'BereketSigortaPersonalAccident',
        component: () =>
          import(
            /* webpackChunkName: "bereket-sigorta" */
            '@/views/company-pages/bereket-sigorta/cross-pages/PersonalAccidentInsurence'
          )
      },
      {
        path: 'nakliyat-sigortasi',
        name: 'BereketSigortaTransportation',
        component: () =>
          import(
            /* webpackChunkName: "bereket-sigorta" */
            '@/views/company-pages/bereket-sigorta/cross-pages/TransportationInsurance'
          )
      },
      {
        path: 'seyahat-saglik-sigortasi',
        name: 'BereketSigortaTravelInsurance',
        component: () =>
          import(
            /* webpackChunkName: "bereket-sigorta" */
            '@/views/company-pages/bereket-sigorta/cross-pages/TravelHealthInsurance'
          )
      }
    ]
  },
  {
    path: '/axa-sigorta',
    component: () =>
      import(
        /* webpackChunkName: "axa-sigorta" */ '@/views/company-pages/axa-sigorta/AxaSigortaMain'
      ),
    children: [
      {
        path: '',
        name: 'AxaSigorta',
        component: () =>
          import(
            /* webpackChunkName: "axa-sigorta" */ '@/views/company-pages/axa-sigorta/AxaSigorta'
          )
      },
      {
        path: 'arac-kasko-sigortasi',
        name: 'AxaSigortaMotorInsurance',
        component: () =>
          import(
            /* webpackChunkName: "axa-sigorta" */ '@/views/company-pages/axa-sigorta/cross-pages/MotorInsurance'
          )
      },
      {
        path: 'zorunlu-trafik-sigortasi',
        name: 'AxaSigortaCompulsoryTraffic',
        component: () =>
          import(
            /* webpackChunkName: "axa-sigorta" */
            '@/views/company-pages/axa-sigorta/cross-pages/CompulsoryTrafficInsurance'
          )
      },

      {
        path: 'ozel-saglik-sigortasi',
        name: 'AxaSigortHealthInsurance',
        component: () =>
          import(
            /* webpackChunkName: "axa-sigorta" */
            '@/views/company-pages/axa-sigorta/cross-pages/PrivateHealthInsurance'
          )
      },
      {
        path: 'konut-sigortasi',
        name: 'AxaSigortaHomeInsurance',
        component: () =>
          import(
            /* webpackChunkName: "axa-sigorta" */ '@/views/company-pages/axa-sigorta/cross-pages/HomeInsurance'
          )
      },
      {
        path: 'dask-zorunlu-deprem-sigortasi',
        name: 'AxaSigortaDaskInsurance',
        component: () =>
          import(
            /* webpackChunkName: "axa-sigorta" */ '@/views/company-pages/axa-sigorta/cross-pages/DaskInsurance'
          )
      },
      {
        path: 'isyeri-sigortasi',
        name: 'AxaSigortaCompanyInsurance',
        component: () =>
          import(
            /* webpackChunkName: "axa-sigorta" */
            '@/views/company-pages/axa-sigorta/cross-pages/CompanyInsurance'
          )
      },
      {
        path: 'ferdi-kaza-sigortasi',
        name: 'AxaSigortaPersonalAccident',
        component: () =>
          import(
            /* webpackChunkName: "axa-sigorta" */
            '@/views/company-pages/axa-sigorta/cross-pages/PersonalAccidentInsurance'
          )
      },
      {
        path: 'nakliyat-sigortasi',
        name: 'AxaSigortaTransportation',
        component: () =>
          import(
            /* webpackChunkName: "axa-sigorta" */
            '@/views/company-pages/axa-sigorta/cross-pages/TransportationInsurance'
          )
      },
      {
        path: 'seyahat-saglik-sigortasi',
        name: 'AxaSigortaTravelInsurance',
        component: () =>
          import(
            /* webpackChunkName: "axa-sigorta" */
            '@/views/company-pages/axa-sigorta/cross-pages/TravelHealthInsurance'
          )
      }
    ]
  },
  {
    path: '/unico-sigorta',
    component: () =>
      import(
        /* webpackChunkName: "unico-sigorta" */ '@/views/company-pages/unico-sigorta/UnicoSigortaMain'
      ),
    children: [
      {
        path: '',
        name: 'UnicoSigorta',
        component: () =>
          import(
            /* webpackChunkName: "unico-sigorta" */ '@/views/company-pages/unico-sigorta/UnicoSigorta'
          )
      },
      {
        path: 'zorunlu-trafik-sigortasi',
        name: 'UnicoSigortaCompulsoryTraffic',
        component: () =>
          import(
            /* webpackChunkName: "unico-sigorta" */
            '@/views/company-pages/unico-sigorta/cross-pages/CompulsoryTrafficInsurance'
          )
      },
      {
        path: 'arac-kasko-sigortasi',
        name: 'UnicoSigortaMotorInsurance',
        component: () =>
          import(
            /* webpackChunkName: "unico-sigorta" */
            '@/views/company-pages/unico-sigorta/cross-pages/MotorInsurance'
          )
      },
      {
        path: 'ozel-saglik-sigortasi',
        name: 'UnicoSigortHealthInsurance',
        component: () =>
          import(
            /* webpackChunkName: "unico-sigorta" */
            '@/views/company-pages/ak-sigorta/cross-pages/PrivateHealthInsurance'
          )
      },
      {
        path: 'tamamlayici-saglik-sigortasi',
        name: 'UnicoSigortaComplementaryHealth',
        component: () =>
          import(
            /* webpackChunkName: "unico-sigorta" */
            '@/views/company-pages/ak-sigorta/cross-pages/ComplementaryHealthInsurance'
          )
      },
      {
        path: 'seyahat-saglik-sigortasi',
        name: 'UnicoSigortaTravelInsurance',
        component: () =>
          import(
            /* webpackChunkName: "unico-sigorta" */
            '@/views/company-pages/unico-sigorta/cross-pages/TravelHealthInsurance'
          )
      },
      {
        path: 'konut-sigortasi',
        name: 'UnicoSigortaHomeInsurance',
        component: () =>
          import(
            /* webpackChunkName: "unico-sigorta" */
            '@/views/company-pages/unico-sigorta/cross-pages/HomeInsurance'
          )
      },
      {
        path: 'ferdi-kaza-sigortasi',
        name: 'UnicoSigortaPersonalAccident',
        component: () =>
          import(
            /* webpackChunkName: "unico-sigorta" */
            '@/views/company-pages/unico-sigorta/cross-pages/PersonalAccidentInsurance'
          )
      },
      {
        path: 'dask-zorunlu-deprem-sigortasi',
        name: 'UnicoSigortaDaskInsurance',
        component: () =>
          import(
            /* webpackChunkName: "unico-sigorta" */
            '@/views/company-pages/unico-sigorta/cross-pages/DaskInsurance'
          )
      },
      {
        path: 'isyeri-sigortasi',
        name: 'UnicoSigortaCompanyInsurance',
        component: () =>
          import(
            /* webpackChunkName: "unico-sigorta" */
            '@/views/company-pages/unico-sigorta/cross-pages/CompanyInsurance'
          )
      },
      {
        path: 'nakliyat-sigortasi',
        name: 'UnicoSigortaTransportation',
        component: () =>
          import(
            /* webpackChunkName: "unico-sigorta" */
            '@/views/company-pages/unico-sigorta/cross-pages/TransportationInsurance'
          )
      }
    ]
  },

  {
    path: '/zurich-sigorta',
    component: () =>
      import(
        /* webpackChunkName: "zurich-sigorta" */ '@/views/company-pages/zurich-sigorta/ZurichSigortaMain'
      ),
    children: [
      {
        path: '',
        name: 'ZurichSigorta',
        component: () =>
          import(
            /* webpackChunkName: "zurich-sigorta" */ '@/views/company-pages/zurich-sigorta/ZurichSigorta'
          )
      },
      {
        path: 'zorunlu-trafik-sigortasi',
        name: 'ZurichSigortaCompulsoryTraffic',
        component: () =>
          import(
            /* webpackChunkName: "zurich-sigorta" */
            '@/views/company-pages/zurich-sigorta/cross-pages/CompulsoryTrafficInsurance'
          )
      },
      {
        path: 'arac-kasko-sigortasi',
        name: 'ZurichSigortaMotorInsurance',
        component: () =>
          import(
            /* webpackChunkName: "zurich-sigorta" */
            '@/views/company-pages/zurich-sigorta/cross-pages/MotorInsurance'
          )
      },
      {
        path: 'seyahat-saglik-sigortasi',
        name: 'ZurichSigortaTravelInsurance',
        component: () =>
          import(
            /* webpackChunkName: "zurich-sigorta" */
            '@/views/company-pages/zurich-sigorta/cross-pages/TravelHealthInsurance'
          )
      },
      {
        path: 'konut-sigortasi',
        name: 'ZurichSigortaHomeInsurance',
        component: () =>
          import(
            /* webpackChunkName: "zurich-sigorta" */
            '@/views/company-pages/zurich-sigorta/cross-pages/HomeInsurance'
          )
      },
      {
        path: 'ferdi-kaza-sigortasi',
        name: 'ZurichSigortaPersonalAccident',
        component: () =>
          import(
            /* webpackChunkName: "zurich-sigorta" */
            '@/views/company-pages/zurich-sigorta/cross-pages/PersonalAccidentInsurance'
          )
      },
      {
        path: 'dask-zorunlu-deprem-sigortasi',
        name: 'ZurichSigortaDaskInsurance',
        component: () =>
          import(
            /* webpackChunkName: "zurich-sigorta" */
            '@/views/company-pages/zurich-sigorta/cross-pages/DaskInsurance'
          )
      },
      {
        path: 'isyeri-sigortasi',
        name: 'ZurichSigortaCompanyInsurance',
        component: () =>
          import(
            /* webpackChunkName: "zurich-sigorta" */
            '@/views/company-pages/zurich-sigorta/cross-pages/CompanyInsurance'
          )
      },
      {
        path: 'nakliyat-sigortasi',
        name: 'ZurichSigortaTransportation',
        component: () =>
          import(
            /* webpackChunkName: "zurich-sigorta" */
            '@/views/company-pages/zurich-sigorta/cross-pages/TransportationInsurance'
          )
      }
    ]
  }
]
