export default [
  {
    path: "/sigorta-sozlugu",
    name: "Dictionary",
    component: () =>
      import(
        /* webpackChunkName: "dictionary-insurance" */ "@/views/dictionary/Dictionary"
      )
  },
  {
    path: "/sigorta-sozlugu/:url",
    name: "DictionaryDetail",
    component: () =>
      import(
        /* webpackChunkName: "dictionary-detail-insurance" */ "@/views/dictionary-detail/DictionaryDetail"
      )
  }
]
