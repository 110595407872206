export default {
  state: {
    plateIsExist: null,
    offerForm: {
      customer: {
        insuranceType: null,
        name: null,
        surname: null,
        identityNumber: '',
        mobileNumber: '',
        passportNumber: null,
        mail: null,
        job: null,

        vehicle: {
          licensePlate: null,
          licenseSerialNo: null,
          licenseOrderNo: null,
          vehicleModel: null
        }
      },
      insuranceType: null
    }
  },
  mutations: {
    uppercaseTolicensePlate (state, payload) {
      state.offerForm.customer.vehicle.licensePlate = payload
    },
    resetOfferFormState (state) {
      state.offerForm = {
        customer: {
          insuranceType: null,
          name: null,
          surname: null,
          identityNumber: ' ',
          mobileNumber: '',
          passportNumber: null,
          mail: null,
          job: null,
          vehicle: {
            licensePlate: null,
            licenseSerialNo: null,
            licenseOrderNo: null,
            vehicleModel: null
          }
        },
        insuranceType: null
      }
    },
    setUpdatedData (state, payload) {
      state.offerForm.customer.name = payload.name
      state.offerForm.customer.surname = payload.surname
      state.offerForm.customer.mobileNumber = payload.mobileNumber
      state.offerForm.customer.mail = payload.mail
      state.offerForm.customer.city = payload.city
      state.offerForm.customer.district = payload.district
      state.offerForm.customer.job = payload.job
      state.offerForm.customer.vehicle.licenseSerialNo = payload.licenseSerialNo
    },
    setPlateAndIdentity (state, payload) {
      state.offerForm.customer.vehicle.licensePlate =
        payload.customer.vehicle.licensePlate
      state.offerForm.customer.identityNumber = payload.customer.identityNumber
    },
    setInsuranceType (state, payload) {
      state.offerForm.insuranceType = payload
      state.offerForm.customer.insuranceType = payload
    },
    setPlateStatus (state, payload) {
      state.plateIsExist = payload
      if (!payload) {
        state.offerForm.customer.vehicle.licensePlate = null
      }
    }
  },
  actions: {},
  getters: {
    _getOfferForm: state => state.offerForm,
    _getInsuranceType: state => state.insuranceType,
    _getPlateIsExist: state => state.plateIsExist
  }
}
