import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import vuetify from "./plugins/vuetify"
import "@mdi/font/css/materialdesignicons.css" // Ensure you are using css-loader
import VueMeta from "vue-meta"
import store from "./store"
import VueSplide from "@splidejs/vue-splide"
import axios from "axios"
import RestApiConstants from "@/data/RestApiConstants"
import VueMask from "v-mask"
import VueSanitize from "vue-sanitize"
import VueHead from "vue-head"

const defaultOptions = {
  allowedTags: ["a", "b"],
  allowedAttributes: {
    a: ["href"]
  }
}
Vue.use(VueSanitize, defaultOptions)

Vue.config.productionTip = false
Vue.use(VueSplide)
Vue.use(VueMeta)
Vue.use(VueMask)
Vue.use(VueHead)

axios.defaults.headers = RestApiConstants.HEADERS

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered"))
}).$mount("#app")
