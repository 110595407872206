export default [
  {
    path: "/blog",
    name: "Blog",
    beforeEnter() {
      location.href = "http://blog.alokasko.com"
    }
  },
  {
    path: "/yazar/:id",
    name: "AuthorPage",
    beforeEnter() {
      location.href = "http://blog.alokasko.com"
    }
  },
  {
    path: "/blog/detay/:url",
    name: "BlogDetail",
    beforeEnter() {
      location.href = "http://blog.alokasko.com"
    }
  }
]
