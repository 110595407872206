import Vue from 'vue'
import VueRouter from 'vue-router'
import companyPages from '@/router/company-pages'
import categoryPages from '@/router/category-pages'
import blogPages from '@/router/blog-pages'
import dictionaryPages from '@/router/dictionary-pages'
import helpPages from '@/router/help-pages'
import aboutPages from '@/router/about-pages'
// import getOfferPages from '@/router/get-offer'
import Meta from 'vue-meta'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "homepage" */'@/views/homepage/Homepage')
  },
  {
    path: '/kasko-deger-hesaplama',
    name: 'insurancevalue',
    component: () => import(/* webpackChunkName: "insurance-value" */'@/views/insurance-value/InsuranceValue')
  },
  {
    path: '*',
    redirect: '/'
  },
  ...companyPages,
  ...categoryPages,
  ...blogPages,
  ...dictionaryPages,
  ...helpPages,
  ...aboutPages
  // ...getOfferPages
]

Vue.use(Meta)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

export default router
