export default [
  {
    path: '/hasar-aninda',
    name: 'TimeOfDamage',
    component: () =>
      import(
        /* webpackChunkName: "damage-insurance" */ '@/views/time-of-damage/TimeOfDamage'
      )
  }
]
